import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import AlertBanner from '@components/common/AlertBanner/AlertBanner';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import useWindowSize from '@components/common/hooks/useWindowSize';
import ConfirmModal from '@components/common/Modals/ConfirmModal/ConfirmModal';
import ContestModal from '@components/common/Modals/ContestModal/ContestModal';
import OdvpModal from '@components/common/Modals/OdvpModal/OdvpModal';
import { M_DEVICE } from '@components/lpc/Constants';
import ContestBanner from '@components/lpc/Contest/ContestBanner/ContestBanner';
import HeroBanner from '@components/lpc/Home/HeroBanner/HeroBanner';
import PaymentOptions from '@components/lpc/Home/PaymentOptions/PaymentOptions';
import PleasedToOffer from '@components/lpc/Home/PleasedToOffer/PleasedToOffer';
import SimpleService from '@components/lpc/Home/SimpleService/SimpleService';
import Layout from '@components/lpc/Layout/Layout';
import Banner from '@components/lpc/Odvp/Banner/Banner';
import OsflmBanner from '@components/lpc/Osflm/Banner';
import { HomePageAlert } from '@models/BannerModel/BannerPagesModel';
import AlertsService from '@services/domain/Lpc/AlertsService';
import NotificationsService from '@services/domain/Lpc/NotificationsService';
import { getRoute, ROUTE } from '@services/http/Route';
import { addMinutes } from '@services/utils/DateService';
import { getItemFromStorage, setItemToStorage } from '@services/utils/StorageService';
import StringService from '@services/utils/StringService';

import css from './HomePage.module.scss';

const HomePage = () => {
    const { t } = useTranslateLpc('lpc-footer');
    const router = useRouter();
    const [hideOdvpBanner, setHideOdvpBanner] = useState<boolean>(true);
    const [hideOdvpModal, setHideOdvpModal] = useState<boolean>(true);
    const [hideContestModal, setHideContestModal] = useState<boolean>(true);
    const [hideOsflmBanner, setHideOsflmBanner] = useState<boolean>(
        process.env.hideOsflmBanner === 'true' || getItemFromStorage('local', 'hideOsflmBanner'),
    );
    const [hideContestBanner, setHideContestBanner] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<string>('');

    const [globalAlerts, setGlobalAlerts] = useState({ enabled: false, frMessage: '' });
    const [activeContestBanner, setActiveContestBanner] = useState(null);
    const [showGlobalAlerts, setShowGlobalAlerts] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem('activeContestBanner') == null) {
            const lastTry = JSON.parse(localStorage.getItem('lastTryActiveContestBanner'));
            if (lastTry == null || new Date() > addMinutes(new Date(lastTry), 1)) {
                getActiveContestBanner();
            }
        } else {
            const contest = JSON.parse(localStorage.getItem('activeContestBanner'));
            const date = new Date(contest.time);
            if (addMinutes(date, 1) < new Date()) {
                localStorage.removeItem('activeContestBanner');
                getActiveContestBanner();
            } else {
                setActiveContestBanner(contest);
            }
        }
    }, []);

    const getActiveContestBanner = () => {
        AlertsService.getActiveContestBanner().then((res) => {
            const contest = res.data;
            if (contest) {
                contest.time = new Date();
                setActiveContestBanner(contest);
                localStorage.setItem('activeContestBanner', JSON.stringify(contest));
            }
            localStorage.setItem('lastTryActiveContestBanner', JSON.stringify(new Date()));
        });
    };

    useEffect(() => {
        setHideOdvpBanner(process.env.hideOdvpBanner === 'true' || getItemFromStorage('local', 'hideOdvpBanner'));
        setHideOdvpModal(process.env.hideOdvpBanner === 'true' || getItemFromStorage('local', 'hideOdvpModal'));
        setHideContestModal(process.env.hideContestModal === 'true' || getItemFromStorage('local', 'hideEasterModal'));
        const alert = JSON.parse(localStorage.getItem('globalAlerts'));
        if (AlertsService.checkShownPossibility(alert, HomePageAlert)) {
            setGlobalAlerts(alert);
            setShowGlobalAlerts(true);
        }
    }, []);

    const { width } = useWindowSize();

    useEffect(() => {
        const uniqUP = router.query.UpdateEmailUniq;
        if (uniqUP) {
            NotificationsService.updateEmail(uniqUP.toString())
                .then(() => {
                    setModalType('success-modal');
                    setShowSuccessModal(true);
                })
                .catch((err) => {
                    if (err.code === 'ALREADY_USED') {
                        setModalType('failed-modal');
                        setShowSuccessModal(true);
                    } else if (err.code === 'NOT_ACTIVE') {
                        setModalType('not-active-modal');
                        setShowSuccessModal(true);
                    } else {
                        setModalType('error-modal');
                        setShowSuccessModal(true);
                    }
                });
        }
    }, []);

    useEffect(() => {
        if (
            Date.now() >= Date.parse('04 Jun 2022 00:00:00 GMT') &&
            Date.now() < Date.parse('15 Jun 2022 00:00:00 GMT') &&
            getItemFromStorage('local', 'hideOsflmBanner')
        ) {
            setItemToStorage('local', 'hideOsflmBanner', false);
        }
    }, []);

    const onHideOdvpBanner = () => {
        setItemToStorage('local', 'hideOdvpBanner', true);
        setHideOdvpBanner(true);
    };

    const onHideOdvpModal = () => {
        setItemToStorage('local', 'hideOdvpModal', true);
        setHideOdvpModal(true);
    };

    const onHideContestModal = () => {
        setItemToStorage('local', 'hideEasterModal', true);
        setHideContestModal(true);
    };

    const onHideOsflmBanner = () => {
        setItemToStorage('local', 'hideOsflmBanner', true);
        setHideOsflmBanner(true);
    };

    const onHideContestBanner = () => {
        setHideContestBanner(true);
        localStorage.removeItem('activeContestBanner');
    };

    useEffect(() => {
        if (router.query.action === 'create-pot') {
            router.push(getRoute(ROUTE.LPC.POT.CREATE_POT));
        }
    }, [router]);

    return (
        <>
            <Layout isHomePage showLandBot>
                <div className={css.homePageWrapper}>
                    {showGlobalAlerts && (
                        <AlertBanner message={globalAlerts.frMessage} customClass={css.homePageWrapper__alert_banner} />
                    )}
                    <HeroBanner />

                    <>
                        <PleasedToOffer />
                        <PaymentOptions />
                        <SimpleService />
                    </>

                    {!hideOdvpBanner && <Banner onHideOdvpBanner={onHideOdvpBanner} />}
                    {!hideOdvpModal && width >= M_DEVICE && (
                        <OdvpModal visible={!hideOdvpModal} onHide={onHideOdvpModal} />
                    )}
                    {!hideContestModal && width >= M_DEVICE && (
                        <ContestModal visible={!hideContestModal} onHide={onHideContestModal} />
                    )}
                    {activeContestBanner && !hideContestBanner && (
                        <ContestBanner
                            activeContestBanner={activeContestBanner}
                            onHideContestBanner={onHideContestBanner}
                        />
                    )}
                    {!hideOsflmBanner && <OsflmBanner onHideBanner={onHideOsflmBanner} />}
                </div>
            </Layout>
            <ConfirmModal
                isVisible={showSuccessModal}
                onHide={() => {
                    setShowSuccessModal(false);
                    window.history.replaceState(
                        {},
                        document.title,
                        StringService.getUrlWithoutParams(document.documentURI),
                    );
                }}
                title={t(`${modalType}.title`)}
                text={t(`${modalType}.text`)}
                cancelLabel={t('success-modal.return')}
                imgUrl={`/front-static/icons/modal/${t(`${modalType}.icons`)}`}
                customIcon={css.customSuccessIcon}
            />
        </>
    );
};

export default HomePage;

import React from 'react';

import { scrollTo } from '@services/utils/DomService';

import css from './ScrollDownButton.module.scss';

type ScrollDownButtonProps = {
    vertical: number;
};

const ScrollDownButton = ({ vertical }: ScrollDownButtonProps) => {
    const handleClick = () => {
        scrollTo(vertical);
    };

    return (
        <div onClick={handleClick} className={css.scroll}>
            <img src="/front-static/icons/navigation/chevron-down-white.svg" alt="chevron-down" />
        </div>
    );
};

export default ScrollDownButton;

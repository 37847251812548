import React from 'react';

import AwesomeModal from 'react-responsive-modal';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import OdvbButton from '@components/lpc/Odvp/OdvbButton/OdvpButton';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './OdvpModal.module.scss';

type ModalProps = {
    visible: boolean;
    onHide: () => void;
    titleCustomClass?: string;
    hideCloseButton?: boolean;
    customClass?: string;
    customRootClass?: string;
    closeOnOverlayClick?: boolean;
    showCloseIcon?: boolean;
    blockScroll?: boolean;
};

const OdvpModal = ({ visible, onHide, closeOnOverlayClick, blockScroll = false }: ModalProps) => {
    const { t } = useTranslateLpc('odvp-2022');

    return (
        <AwesomeModal
            blockScroll={blockScroll}
            closeOnOverlayClick={closeOnOverlayClick}
            center
            classNames={{
                overlay: css.overlay,
                modal: css.modal,
                modalAnimationIn: css.modal__transition_enter,
                overlayAnimationIn: css.modal__transition_enter_active,
                modalAnimationOut: css.modal__transition_exit,
                overlayAnimationOut: css.modal__transition_exit_active,
            }}
            showCloseIcon={false}
            open={visible}
            onClose={() => onHide()}
        >
            <img
                onClick={() => onHide()}
                src={'/front-static/images/odvp/close.svg'}
                alt="close"
                width={11}
                height={11}
                className={css.close__cta}
            />
            <div className={css.title}>{t('modal.title')}</div>
            <div className={css.subtitle}>{t('modal.subtitle')}</div>
            <input autoFocus={true} className={css.fake__input} />
            <OdvbButton
                width={260}
                height={46}
                customClass={css.cta}
                customHref={`${getRoute(
                    ROUTE.LPC.ODVP,
                )}?utm_source=homepage-lpc&utm_medium=pop-odvp&utm_campaign=odvp2022&utm_id=odvp&utm_term=odvp`}
            >
                {t('modal.cta')}
            </OdvbButton>
        </AwesomeModal>
    );
};

export default OdvpModal;

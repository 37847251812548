import React from 'react';

import css from './IconCheckmark.module.scss';

type IconCheckmarkProps = {
    variant: string;
    customClass?: string;
};

const IconCheckmark = ({ customClass, variant }: IconCheckmarkProps) => {
    return (
        <figure className={`${css.container} ${css['container__' + variant]} ${customClass || ''}`}>
            <img src={`/front-static/icons/modal/${variant}.svg`} alt={variant} />
        </figure>
    );
};

export default IconCheckmark;

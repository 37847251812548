import React, { useEffect, useState } from 'react';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';

import css from './Tabs.module.scss';

type TabsProps = {
    onChangeTab: (number) => void;
};

const Tabs = ({ onChangeTab }: TabsProps) => {
    const { t } = useTranslateLpc(['lpc-home']);

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        onChangeTab(activeTab);
    }, [activeTab]);

    return (
        <div className={css.navigation}>
            {[...new Array(3)].map((_, i) => (
                <p
                    key={i}
                    onClick={() => setActiveTab(i)}
                    className={`${css.navigation__tab} ${activeTab === i && css.navigation__tab__active}`}
                >
                    {t(`pleased-to-offer.tab.${i}`)}
                </p>
            ))}
        </div>
    );
};

export default Tabs;

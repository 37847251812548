import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import Button from '@components/common/Button/Button';
import Select from '@components/common/Dropdowns/Select/Select';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import useWindowSize from '@components/common/hooks/useWindowSize';
import IconCheckmark from '@components/common/IconCheckmark/IconCheckmark';
import ConfirmModal from '@components/common/Modals/ConfirmModal/ConfirmModal';
import ScrollDownButton from '@components/lpc/Home/HeroBanner/ScrollDownButton/ScrollDownButton';
import { createPotCategoryModel } from '@models/PotModel/createPotCategoryModel';
import { getRoute, ROUTE } from '@services/http/Route';
import { clearItemFromStorage, setItemToStorage } from '@services/utils/StorageService';
import { getCategoryIconSrc } from '@services/utils/UserService';

import css from './HeroBanner.module.scss';

const HeroBanner = () => {
    const { t } = useTranslateLpc(['lpc-home', 'common', 'lpc-pot-mpp-spending', 'lpc-create-pot']);
    const router = useRouter();
    const { height } = useWindowSize();
    const [selectedCategory, setSelectedCategory] = useState<string>('BIRTHDAY');
    const [listRedirectionModalVisible, setListRedirectionModalVisible] = useState<boolean>(false);

    const potCategoriesList = (category) => {
        return (
            <div className={css.select__category}>
                <img className="category_image" src={getCategoryIconSrc(category)} alt={category} />
                {t(`select.pot-category.${category}`, { ns: 'common' })}
            </div>
        );
    };

    const [categories] = useState<any>(
        createPotCategoryModel.map((item) => {
            return {
                value: item.value,
                label: potCategoriesList(item.value),
            };
        }),
    );

    useEffect(() => {
        setItemToStorage('session', 'temporaryCategory', selectedCategory);
    }, [selectedCategory]);

    useEffect(() => {
        const isList = selectedCategory === 'WEDDING_PACS_GIFT' || selectedCategory === 'BIRTH_GIFT';

        if (isList) {
            setListRedirectionModalVisible(true);
        }
    }, [selectedCategory]);

    return (
        <div className={css.landing}>
            <ConfirmModal
                onHide={() => {
                    setListRedirectionModalVisible(false);
                    setSelectedCategory('BIRTHDAY');
                }}
                onConfirm={() => router.push(ROUTE.WEDDING_LIST)}
                title={t(`modal.${selectedCategory}.title`)}
                confirmLabel={t(`modal.${selectedCategory}.redirect-confirm`)}
                cancelLabel={t(`modal.${selectedCategory}.redirect-cancel`)}
                isVisible={listRedirectionModalVisible}
                iconComponent={<IconCheckmark variant={'list'} />}
                customIcon={css.modal}
                customRootClass={css.modal__overlay}
            />
            <h1>{t('landing.main-title')}</h1>

            <div className={css.landing__main}>
                <Select
                    onSelect={(value) => setSelectedCategory(value)}
                    value={selectedCategory}
                    options={categories}
                    customSelect={css.select}
                    customControl={css.select__control}
                    customMenu={css.select__menu}
                    autoScroll={true}
                />
                <Button
                    mobileFullWidth
                    variant={'primary'}
                    customClass={css.landing__main__primary}
                    onClick={() => {
                        clearItemFromStorage('session', 'temporaryPot');
                        setItemToStorage('local', 'createPotCategory', selectedCategory);
                        router.push(getRoute(ROUTE.LPC.POT.CREATE_POT)).then();
                    }}
                >
                    {t('landing.create')}
                </Button>
                <div className={css.scroll}>
                    <ScrollDownButton vertical={height + 20} />
                </div>
            </div>
            <img className={css.black} src="/front-static/images/home/sphere-black.png" alt="sphere" />
            <img className={css.pink} src="/front-static/images/home/sphere-pink.png" alt="sphere" />
            <img className={css.yellow_small} src="/front-static/images/home/sphere-yellow.png" alt="sphere" />
            <img className={css.yellow_big} src="/front-static/images/home/sphere-yellow.png" alt="sphere" />
            <img
                className={`${css.category} ${css.category__left}`}
                src={getCategoryIconSrc(selectedCategory)}
                alt={selectedCategory}
            />
            <img
                className={`${css.category} ${css.category__right}`}
                src={getCategoryIconSrc(selectedCategory)}
                alt={selectedCategory}
            />
        </div>
    );
};
export default HeroBanner;

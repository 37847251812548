import React from 'react';

import Link from 'next/link';

import HomeHeading from '@components/common/Heading/HomeHeading/HomeHeading';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './PaymentOptions.module.scss';

const PaymentOptions = () => {
    const { t } = useTranslateLpc('lpc-home');

    return (
        <div className={css.wrapper}>
            <div className={css.options}>
                <HomeHeading
                    title={t('payment-options.title')}
                    subtitle={t('payment-options.subtitle')}
                    paragraph={t('payment-options.paragraph')}
                />

                <div className={css.grid}>
                    <section className={css.grid__left}>
                        <div className={`${css.card} ${css.card__left}`}>
                            <div className={css.card__label}>
                                <Text variant={'caption_02'} color={'white'}>
                                    {t('payment-options.ffa')}
                                </Text>
                            </div>
                            <section>
                                <figure className={`${css.icon} ${css.icon__partners}`}>
                                    <img src="/front-static/icons/home/partners.svg" alt="coin" />
                                </figure>
                                <h5>{t('payment-options.card.partners.title')}</h5>
                                <Text customClass={css.card__txt} variant={'body_01'} color={'txt-primary'}>
                                    {t('payment-options.card.partners.txt')}
                                </Text>
                                <Link href={getRoute(ROUTE.LPC.PURCHASING_TYPE.PARTNERS_NETWORKS)}>
                                    <span className={css.card__cta}>{t('payment-options.card.partners.cta')}</span>
                                </Link>
                            </section>
                        </div>
                    </section>

                    <section className={css.grid__right}>
                        <div className={`${css.card} ${css.card__right}`}>
                            <div className={css.card__label}>
                                <Text variant={'caption_02'} color={'white'}>
                                    {t('payment-options.comm')}
                                </Text>
                            </div>
                            <figure className={`${css.icon} ${css.icon__coin}`}>
                                <img src="/front-static/icons/home/coin.svg" alt="coin" />
                            </figure>
                            <h5>{t('payment-options.card.bank-transfer.title')}</h5>
                            <Text customClass={css.card__txt} variant={'body_01'} color={'txt-primary'}>
                                {t('payment-options.card.bank-transfer.txt')}
                            </Text>
                            <Link href={getRoute(ROUTE.LPC.PURCHASING_TYPE.BANK_TRANSFER)}>
                                <span className={css.card__cta}>{t('payment-options.card.partners.cta')}</span>
                            </Link>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default PaymentOptions;

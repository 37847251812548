export const scrollToRef = (ref, additionalOffset?: number) => {
    window.scrollTo({
        top: ref.current.offsetTop - (additionalOffset || 0),
        left: 0,
        behavior: 'smooth',
    });
};

export const scrollTo = (vertical: number, horizontal?: number) => {
    window.scrollTo({
        top: vertical,
        left: horizontal || 0,
        behavior: 'smooth',
    });
};
export const openSharer = (type, url, winWidth, winHeight) => {
    const winTop = screen.height / 2 - winHeight / 2;
    const winLeft = screen.width / 2 - winWidth / 2;

    if (type === 'facebook') {
        window.open(
            'http://www.facebook.com/sharer.php?u=' + url,
            'sharer',
            'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight,
        );
    } else if (type === 'facebook-mobile') {
        window.open('http://m.facebook.com/sharer.php?u=' + url, 'sharer');
    } else if (type === 'messenger-mobile') {
        window.open('fb-messenger://share/?link=' + url, '_top', 'sharer');
    } else if (type === 'twitter-desktop') {
        window.open(
            'https://twitter.com/intent/tweet?text=' + url,
            'sharer',
            'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight,
        );
    } else if (type === 'twitter-mobile') {
        window.open('https://twitter.com/intent/tweet?text=' + url, '_top', 'sharer');
    } else if (type === 'whatsapp-mobile') {
        window.open('whatsapp://send?text=' + url, '_top', 'sharer');
    } else if (type === 'whatsapp-desktop') {
        window.open('https://web.whatsapp.com/send?text=' + url, '_blank');
    } else if (type === 'google') {
        window.open(
            'https://plus.google.com/share?url=' + url,
            'sharer',
            'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight,
        );
    }
};

export default { scrollToRef, scrollTo, openSharer };

import React from 'react';

import AwesomeModal from 'react-responsive-modal';

import css from './ContestModal.module.scss';

type ContestModalProps = {
    visible: boolean;
    onHide: () => void;
    titleCustomClass?: string;
    hideCloseButton?: boolean;
    customClass?: string;
    customRootClass?: string;
    closeOnOverlayClick?: boolean;
    showCloseIcon?: boolean;
    blockScroll?: boolean;
};

const ContestModal = ({ visible, onHide, closeOnOverlayClick, blockScroll = false }: ContestModalProps) => {
    return (
        <div>
            {visible && (
                <AwesomeModal
                    blockScroll={blockScroll}
                    closeOnOverlayClick={closeOnOverlayClick}
                    center
                    classNames={{
                        root: css.custom_contest_overlay,
                        modal: css.contest_modal,
                        modalAnimationIn: css.modal__transition_enter,
                        overlayAnimationIn: css.modal__transition_enter_active,
                        modalAnimationOut: css.modal__transition_exit,
                        overlayAnimationOut: css.modal__transition_exit_active,
                    }}
                    showCloseIcon={false}
                    open={visible}
                    onClose={() => onHide()}
                >
                    <img
                        onClick={(e) => {
                            e.preventDefault();
                            onHide();
                        }}
                        src={'/front-static/images/odvp/close.svg'}
                        alt="close"
                        width={11}
                        height={11}
                        className={css.close__cta}
                    />
                    <div
                        onClick={() =>
                            window.open(
                                'https://adbx.io/jeudepaqueslepotcommun?utm_source=popin&utm_medium=site&utm_campaign=paques&utm_id=paques',
                                '_blank',
                            )
                        }
                        className={css.container}
                    />
                    <input autoFocus={true} className={css.fake__input} />
                </AwesomeModal>
            )}
        </div>
    );
};

export default ContestModal;

import React from 'react';

import Link from 'next/link';

import css from './OdvpButton.module.scss';

type ButtonProps = {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children: React.ReactNode;
    type?: 'button' | 'submit';
    customClass?: string;
    customHref?: string;
    width?: number | string;
    height?: number;
    fontSize?: number;
    borderRadius?: number;
};

const OdvpButton = ({
    children,
    type,
    onClick,
    customClass,
    width,
    height,
    fontSize,
    borderRadius,
    customHref,
}: ButtonProps) => {
    const buttonStyle = {
        width: width,
        height: height,
        fontSize: fontSize,
        borderRadius: borderRadius,
    };

    return (
        <Link href={customHref || '/?action=create-pot'}>
            <button type={type} className={`${css.button} ${customClass || ''}`} style={buttonStyle} onClick={onClick}>
                {children}
            </button>
        </Link>
    );
};

export default OdvpButton;

export const createPotCategoryModel = [
    {
        value: 'BIRTHDAY',
        label: 'BIRTHDAY',
    },
    {
        value: 'DEPARTURE_KITTY',
        label: 'DEPARTURE_KITTY',
    },
    {
        value: 'COMMON_GIFT',
        label: 'COMMON_GIFT',
    },
    {
        value: 'SOLIDARITY_POOL',
        label: 'SOLIDARITY_POOL',
    },
    {
        value: 'PARTY_HONEY_NIGHT',
        label: 'PARTY_HONEY_NIGHT',
    },
    {
        value: 'WEDDING_PACS',
        label: 'WEDDING_PACS',
    },
    {
        value: 'NEW_BIRTH',
        label: 'NEW_BIRTH',
    },
    {
        value: 'HOUSEWARMING',
        label: 'HOUSEWARMING',
    },
    {
        value: 'FRIENDSHOLIDAYS',
        label: 'FRIENDSHOLIDAYS',
    },
    {
        value: 'BEREAVEMENT',
        label: 'BEREAVEMENT',
    },
    {
        value: 'BABY_SHOWER',
        label: 'BABY_SHOWER',
    },
    {
        value: 'TEACHER_GIFT',
        label: 'TEACHER_GIFT',
    },
    {
        value: 'WEDDING_PACS_GIFT',
        label: 'WEDDING_PACS_GIFT',
    },
    {
        value: 'BIRTH_GIFT',
        label: 'BIRTH_GIFT',
    },
];

export const subCategoryLPSEnum = [
    'HEALTH',
    'HANDICAP',
    'ANIMALS',
    'ENVIRONMENT_ECOLOGY',
    'HUMANITARIAN',
    'TRAVEL',
    'PERSONAL_PROJECT',
];

export const concernPersonEnum = ['YOURSELF', 'OTHERS'];

export const enum potCreationTypeEnum {
    LPC = 'LPC',
    LPS = 'SOLIDARITY_POOL',
    LPC_TEACHER_GIFT = 'TEACHER_GIFT',
    LPC_DEPARTURE_KITTY = 'DEPARTURE_KITTY',
    LPC_NEW_BIRTH = 'NEW_BIRTH',
    LPC_WEDDING_PACS = 'WEDDING_PACS',
    LPC_BABY_SHOWER = 'BABY_SHOWER',
    LPC_COMMON_GIFT = 'COMMON_GIFT',
    LPC_HOUSEWARMING = 'HOUSEWARMING',
    LPC_BEREAVEMENT = 'BEREAVEMENT',
    LPC_FRIENDSHOLIDAYS = 'FRIENDSHOLIDAYS',
    LPC_PARTY_HONEY_NIGHT = 'PARTY_HONEY_NIGHT',
}

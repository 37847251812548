import React from 'react';

import Link from 'next/link';

import Button from '@components/common/Button/Button';
import Modal from '@components/common/Modals/Modal/Modal';
import Text from '@components/common/Text/Text';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './ConfirmModal.module.scss';

type ConfirmModalProps = {
    mainTitle?: string;
    title: string;
    text?: string;
    isVisible: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
    isLoading?: boolean;
    onHide?: () => void;
    onConfirm?: () => void;
    customConfirmButton?: string;
    customCancelButton?: string;
    customIcon?: string;
    imgUrl?: string;
    closeOnOverlayClick?: boolean;
    hideCloseButton?: boolean;
    iconComponent?: React.ComponentElement<any, any>;
    customRootClass?: string;
    cancelLink?: string;
};

const ConfirmModal = ({
    isVisible,
    title,
    text,
    confirmLabel,
    cancelLabel,
    onHide,
    onConfirm,
    isLoading,
    customConfirmButton,
    customCancelButton,
    customIcon,
    imgUrl,
    closeOnOverlayClick,
    hideCloseButton,
    iconComponent,
    customRootClass,
    cancelLink,
}: ConfirmModalProps) => {
    return (
        <Modal
            hideCloseButton={hideCloseButton}
            closeOnOverlayClick={closeOnOverlayClick}
            visible={isVisible}
            onHide={onHide}
            customClass={css.custom}
            customRootClass={customRootClass}
        >
            <div className={css.modal}>
                <div
                    className={`${css.modal__icon} ${customIcon || ''}`}
                    style={{ backgroundImage: `url(${imgUrl || ''})` }}
                >
                    {iconComponent && iconComponent}
                </div>

                <h5>{title}</h5>
                <Text
                    htmlText={text || ''}
                    customClass={css.modal__text}
                    variant={'body_02'}
                    color={'ui-secondary'}
                    html
                />
                <div className={css.modal__btn__container}>
                    {confirmLabel && (
                        <Button
                            margin={'0 0 8px 0'}
                            variant={'primary'}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                            onClick={() => onConfirm()}
                            customClass={customConfirmButton || ''}
                        >
                            {confirmLabel}
                        </Button>
                    )}
                    {cancelLabel && cancelLink && (
                        <Link href={getRoute(ROUTE.LPC.POT.POT, cancelLink)} legacyBehavior>
                            <Button customClass={customCancelButton || ''} variant={'secondary'}>
                                {cancelLabel}
                            </Button>
                        </Link>
                    )}
                    {cancelLabel && !cancelLink && (
                        <Button customClass={customCancelButton || ''} variant={'secondary'} onClick={() => onHide()}>
                            {cancelLabel}
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;

import React, { useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './Banner.module.scss';

type BannerProps = {
    onHideBanner: () => void;
};

const OsflmBanner = ({ onHideBanner }: BannerProps) => {
    const router = useRouter();
    const { t } = useTranslateLpc('lpc-osflm');

    useEffect(() => {
        if (Date.now() < Date.parse('04 Jun 2022 00:00:00 GMT') || Date.now() > Date.parse('15 Jun 2022 00:00:00 GMT'))
            onHideBanner();
    }, []);

    return (
        <div
            onClick={() => {
                router.push(getRoute(ROUTE.LPC.OSFLM));
            }}
            className={css.contest__banner}
        >
            <div className={css.contest__banner__mobile}>
                <div className={css.contest__banner__header}>
                    <div className={css.logos}>
                        <img
                            src={'/front-static/images/osflm/osflm-logo-lpc.png'}
                            height={13}
                            className={css.contest__banner__lpcLogo}
                        />
                        <img
                            src={'/front-static/images/osflm/osflm-logo-virgin.png'}
                            height={45}
                            className={css.contest__banner__virginLogo}
                        />
                    </div>
                    <Link href={'/on-se-fait-la-malle-2022'}>
                        <button className={css.contest__banner__participateBtn}>{t('participate')}</button>
                    </Link>{' '}
                </div>
                <div className={css.contest__banner__osflm_logo}>
                    <img src={'/front-static/images/osflm/osflm-text.png'} height={113} />
                </div>
                <div>
                    <div className={css.contest__banner__date}>{t('description-1.first-part')}</div>
                    <div className={css.contest__banner__desc}>
                        {t('description-1.second-part')}
                        <span className={css.contest__banner__amount}>{t('description-1.third-part')}</span>{' '}
                        {t('description-1.fourth-part')}
                    </div>
                </div>
            </div>

            <img
                onClick={(event) => {
                    onHideBanner();
                    event.stopPropagation();
                }}
                className={css.contest__banner__close}
                src="/front-static/icons/navigation/close-white.svg"
                alt="close"
            />
        </div>
    );
};

export default OsflmBanner;

import React, { useEffect, useState } from 'react';

import CustomSlider from '@components/common/CustomSlider/CustomSlider';
import HomeHeading from '@components/common/Heading/HomeHeading/HomeHeading';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import useWindowSize from '@components/common/hooks/useWindowSize';
import Text from '@components/common/Text/Text';
import { M_DEVICE } from '@components/lpc/Constants';
import Tabs from '@components/lpc/Home/PleasedToOffer/Tabs/Tabs';

import css from './PleasedToOffer.module.scss';

const PleasedToOffer = () => {
    const { t } = useTranslateLpc(['lpc-home']);
    const { width } = useWindowSize();

    const [activeTab, setActiveTab] = useState(0);
    const [isLoadingPage, setIsLoadingPage] = useState(false);

    const handleClick = (tab) => {
        setActiveTab(tab);
        setIsLoadingPage(true);
        move();
    };

    const paging = (index) => {
        return (
            <div>
                <span className={`${css.slider__dots} ${activeTab === index && css.slider__dots__active}`} />
            </div>
        );
    };

    useEffect(() => {
        setTimeout(() => {
            isLoadingPage && setIsLoadingPage(false);
        }, 600);
    }, [isLoadingPage]);

    const move = () => {
        const elem = document.getElementById('myBar');
        let width = 1;
        const id = setInterval(frame, 4);

        function frame() {
            if (width >= 100) {
                clearInterval(id);
                elem.style.opacity = '0';
            } else {
                elem.style.opacity = '1';
                width++;
                elem.style.width = width + '%';
            }
        }
    };

    const slideArray = [
        {
            id: 0,
            imgSrc: '/front-static/images/home/tab-mobile-0.png',
        },
        {
            id: 1,
            imgSrc: '/front-static/images/home/tab-mobile-1.png',
        },
        {
            id: 2,
            imgSrc: '/front-static/images/home/tab-mobile-2.png',
        },
    ];

    return (
        <div className={css.offer}>
            <HomeHeading
                customTitle={css.title}
                customSubtitle={css.subtitle}
                title={t('pleased-to-offer.title')}
                subtitle={t('pleased-to-offer.subtitle')}
                paragraph={t('pleased-to-offer.paragraph')}
            />

            <img className={css.message} src={`front-static/images/home/${activeTab}/message.png`} alt="message" />
            <img className={css.heart} src="/front-static/images/home/heart.svg" alt="heart" />
            <img className={css.sphere__left} src="/front-static/images/home/sphere-blue.png" alt="sphere" />
            <img className={css.sphere__right} src="/front-static/images/home/sphere-blue.png" alt="sphere" />
            <img
                className={css.participation_1}
                src={`/front-static/images/home/${activeTab}/participation-1.png`}
                alt="participation"
            />
            <img
                className={css.participation_2}
                src={`/front-static/images/home/${activeTab}/participation-2.png`}
                alt="participation"
            />
            <img className={css.poteurs} src="/front-static/images/home/poteurs.png" alt="poteurs" />

            {width > M_DEVICE ? (
                <>
                    <Tabs onChangeTab={(tab) => handleClick(tab)} />

                    <div className={css.container}>
                        <img src="/front-static/images/home/toolbar.svg" alt="toolbar" />
                        <div id="myBar" className={css.toolbar__loader} style={{ width: '1%' }} />
                        {isLoadingPage ? (
                            <img className={css.toolbar__icon} src="/front-static/icons/home/close.svg" alt="close" />
                        ) : (
                            <img
                                className={css.toolbar__icon}
                                src="/front-static/icons/home/refresh.svg"
                                alt="refresh"
                            />
                        )}
                        <div className={css.container__white}>
                            <div
                                className={`${css.offer__card} ${isLoadingPage && css.offer__card__active}`}
                                style={{ backgroundImage: `url(/front-static/images/home/tab-${activeTab}.png)` }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className={css.container}>
                    <Text customClass={css.slider__title} variant={'caption_01'} color={'txt-primary'}>
                        {t(`pleased-to-offer.tab.${activeTab}`)}
                    </Text>

                    <CustomSlider
                        onActiveStep={(value) => setActiveTab(value)}
                        customPaging={(index) => paging(index)}
                        sliderClass={css.slider}
                        autoplay={false}
                        dots={true}
                    >
                        {slideArray.map((item) => (
                            <div key={item.id} className={css.slider__card}>
                                <div
                                    className={css.slider__card__img}
                                    style={{
                                        backgroundImage: `url(${item.imgSrc})`,
                                    }}
                                />
                            </div>
                        ))}
                    </CustomSlider>
                </div>
            )}
        </div>
    );
};

export default PleasedToOffer;

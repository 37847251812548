import React from 'react';

import Text from '@components/common/Text/Text';

import css from './HomeHeading.module.scss';

type HeadingProps = {
    title: string;
    subtitle?: string;
    paragraph?: string;
    margin?: string;
    customTitle?: string;
    customSubtitle?: string;
    customHeading?: string;
    customParagraph?: string;
    icon?: string;
    customStyle?: any;
};

const HomeHeading = ({
    title,
    subtitle,
    paragraph,
    margin,
    customTitle,
    customSubtitle,
    customHeading,
    customParagraph,
    icon,
    customStyle,
}: HeadingProps) => {
    const style = {
        margin: margin,
    };

    return (
        <div className={`${css.heading} ${customHeading || ''}`} style={style}>
            <div className={css.heading__title}>
                {icon && <img src={icon} alt={'icon'} />}
                <h1 className={customTitle || ''} style={customStyle || null}>
                    {title}
                </h1>
            </div>
            <h1 className={`${css.heading__subtitle} ${customSubtitle || ''}`}>{subtitle}</h1>
            <div>
                <Text
                    variant={'body_00'}
                    color={'txt-primary'}
                    html
                    htmlText={paragraph}
                    customClass={customParagraph || ''}
                    customStyle={customStyle}
                />
            </div>
        </div>
    );
};

export default HomeHeading;

import React from 'react';

import HomeHeading from '@components/common/Heading/HomeHeading/HomeHeading';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import Text from '@components/common/Text/Text';

import css from './SimpleService.module.scss';

const SimpleService = () => {
    const { t } = useTranslateLpc('lpc-home');

    const serviceArray = [
        {
            title: t('service.card.create.title'),
            desc: t('service.card.create.desc'),
            image: '/front-static/icons/home/create.svg',
        },
        {
            title: t('service.card.share.title'),
            desc: t('service.card.share.desc'),
            image: '/front-static/icons/home/share.svg',
        },
        {
            title: t('service.card.participate.title'),
            desc: t('service.card.participate.desc'),
            image: '/front-static/icons/home/participate.svg',
        },
    ];

    return (
        <div className={css.wrapper}>
            <HomeHeading title={t('service.title')} subtitle={t('service.subtitle')} />

            <div className={css.service}>
                {serviceArray.map((item) => (
                    <div key={item.title} className={css.service__card}>
                        <div className={css.service__card__image} style={{ backgroundImage: `url(${item.image})` }} />
                        <h5>{item.title}</h5>
                        <Text variant={'body_01'} color={'txt-primary'}>
                            {item.desc}
                        </Text>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SimpleService;

import React from 'react';

import OdvbButton from '@components/lpc/Odvp/OdvbButton/OdvpButton';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './Banner.module.scss';

type BannerProps = {
    onHideOdvpBanner: () => void;
};

const Banner = ({ onHideOdvpBanner }: BannerProps) => {
    return (
        <div className={css.banner}>
            <div className={css.banner__first}>
                <img className={css.banner__odvp} src="/front-static/images/odvp/odvp-banner-name.png" alt="odvp" />
            </div>
            <div className={css.banner__second}>
                <div className={css.banner__text_message_1}>Du 01 au 14 décembre </div>
            </div>
            <div className={css.banner__fourth}>
                <OdvbButton
                    customHref={`${getRoute(
                        ROUTE.LPC.ODVP,
                    )}?utm_source=homepage-lpc&utm_medium=bandeau-odvp&utm_campaign=odvp2022&utm_id=odvp&utm_term=odvp`}
                    width={183}
                    height={43}
                    fontSize={16}
                    borderRadius={50}
                    customClass={css.participateBtn}
                >
                    Participer
                </OdvbButton>
            </div>
            <img
                onClick={() => onHideOdvpBanner()}
                className={css.close}
                src="/front-static/icons/navigation/close-pink.svg"
                alt="close"
            />
        </div>
    );
};

export default Banner;
